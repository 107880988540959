@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: 'Solvay';
    src:  url('assets/KlavikaRegular.woff') format('woff');
  }

  .solvayLogo{
    width: 250px;
    height:inherit;
  }

  input, button, label, h2, a,.solvay{
    font-family: 'Solvay';
  }

  .vertical-align {
      display: flex;
      align-items: center;
  }

.topnav {
  overflow: hidden;
}

.topnav a {
  float: left;
  color: black;
  text-align: center;
  padding-top: 20px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: lightsteelblue;
  color: white;
}
